@font-face {
  font-family: 'Montserat';
  src: local('Montserat'),
    url(assets/fonts/Montserrat-VariableFont_wght.ttf) format('truetype'),
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Montserat', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: inherit;
  }

  ul {
    list-style-type: none;
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 0;
  }



  li {
    // font-size: min(1vw, 2vh);
    color: #283278;
  }

  // span {
  //   text-decoration: none;
  //   color: #283278;
  // }

  // strong {
  //   display: contents;
  // }

  // b {
  //   display: contents;
  // }


  // h1 {
  //   font-size: min(3vw, 6vh);
  //   letter-spacing: 0.3vmin;
  //   margin-block: 0vmin;
  //   margin-inline: 0vmin;
  // }


  h2 {
    font-size: 5em;
    color: #283278;
    line-height: 2em;
    font-weight: 600;
    margin-block: 0;
  }

  h3 {
    font-size: 3.5em;
    margin-block: 0;
    color: #283278;
    font-weight: 550;
  }

  h6 {
    margin: 0;
    margin-block: 0;
    color: #283278;
    font-size: 3.5em;
    font-weight: 600;
  }

  p {
    line-height: 2em;
    margin-block: 0;
    color: #283278;
    font-size: 3.5em;

  }

  // hr {
  //   margin-block: 0;
  //   margin-inline: 0;
  // }
  strong {
    font-weight: 600;
  }

  b {
    font-weight: 600;
  }


  button {
    background-color: #ea8b2d;
    color: white;
    border-radius: 0.1em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    padding: 1em;
    user-select: none;
    -webkit-user-select: none;
    transition: all 250ms;
    touch-action: manipulation;
    font-size: 2.8em;
    font-weight: 500;

    border: 0;
    font-family: inherit;
    cursor: pointer;
    padding-block: 0.9em;
    padding-inline: 1em;
  }

  button:hover,
  button:focus {
    background-color: #ea8b2d;
  }

  button:hover {
    transform: translateY(-1px);
  }

  button:active {
    background-color: #fb8332;
    transform: translateY(0);
  }